import Img from '../UI/Img';
import Project from '../Project';
import Hi from '../Hi';
import Container from '../UI/Container';

const ProjectJCollins = () => {
  return (
    <Project projectID="8">
      <Hi
        title="James Collins Performance Nutrition"
        description={
          <>
            <p>
              The James Collins Nutrition website has been refreshed with a
              clean and modern design and enhanced user experience and updated
              CMS in WordPress. Performance optimizations ensured faster load
              times and better mobile responsiveness.
            </p>
          </>
        }
        skills={
          <>
            UI<em>, </em>Responsive Design<em>, </em>Wordpress
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Development</p>
          </>
        }
      />

      <Container className="container content">
        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>01.</small>
                <h2>Landing page</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="1.webp" alt="x" className="browser" />
            <Img src="2.webp" alt="x" className="browser" />
            <Img src="4.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block row">
          <div className="col-lg-4">
            <div className="description">
              <div className="sticky-top">
                <small>02.</small>
                <h2>Content pages</h2>
              </div>
            </div>
          </div>
          <div className="visuals col-lg-8">
            <Img src="5.webp" alt="x" className="browser" />
            <Img src="6.webp" alt="x" className="browser" />
          </div>
        </div>
      </Container>
    </Project>
  );
};

export default ProjectJCollins;
