import Img from "../UI/Img";
import Project from "../Project";
import Hi from "../Hi";
import Container from "../UI/Container";

export const ProjectCurDir = () => {
  return (
    <Project projectID="5" prllxSpeed={2}>
      <Hi
        title="Currencies Direct"
        description={
          <>
            <p>
              Design & Development work done for a fintech company, with
              contributions in UI development, interface designs, prototyping,
              and designing user journeys.
            </p>
          </>
        }
        skills={
          <>
            HTML5 + CSS3 + Javascript<em>, </em>Responsive Design
            <em>, </em>UI Design & Prototyping<em>, </em>Sketch
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Development</p>
          </>
        }
      />

      <Container className="container content">
        <div className="block row">
          <div className="col-lg-4"></div>
          <div className="visuals col-lg-8">
            <Img src="1.webp" alt="x" className="browser" />

            <Img src="2.webp" alt="x" className="browser" />
          </div>
        </div>

        <div className="block">
          <div className="row">
            <div className="visuals col-lg-6">
              <Img src="3.webp" alt="x" className="browser" />
              <Img src="5.webp" alt="x" className="browser" />
            </div>
            <div className="visuals col-lg-6">
              <Img src="4.webp" alt="x" className="browser" />
            </div>
          </div>
        </div>
      </Container>
    </Project>
  );
};
