import Img from "../UI/Img";
import Project from "../Project";
import Parallax from "react-rellax";
import Hi from "../Hi";
import Container from "../UI/Container";

export const ProjectSocii = () => {
  return (
    <Project projectID="4" prllxSpeed={3}>
      <Hi
        title="Socii Money"
        description={
          <p>
            I was approached to contribute to the creation of preliminary
            designs for a financial product app, intended for promotional
            purposes. These designs were developed from rough wireframes,
            further elaborating on the initial concepts to enhance the user
            experience.
          </p>
        }
        skills={
          <>
            Mobile design<em>, </em>UX<em>, </em>Figma
          </>
        }
        involvement={
          <>
            <p>Design</p>
          </>
        }
      />

      <Container className="container content">
        <div className="block">
          <div className="row">
            <div className="visuals col-lg-4 pe-4 ps-4">
              <Img src="3.webp" alt="x" className="mobile" />
            </div>
            <div className="visuals col-lg-4 pe-4 ps-4">
              <Parallax speed={-1}>
                <Img src="2.webp" alt="x" className="mobile" />
              </Parallax>
            </div>
            <div className="visuals col-lg-4 pe-4 ps-4">
              <Img src="4.webp" alt="x" className="mobile" />
            </div>
          </div>
        </div>
      </Container>
    </Project>
  );
};
