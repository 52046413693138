import Project from "../Project";
import Hi from "../Hi";

export const ProjectTgwLanding = () => {
  return (
    <Project projectID="3" prllxSpeed={0}>
      <Hi
        title="The landing page for The Good Workshop"
        description={
          <>
            {" "}
            <p>
              A collaborative design for the The Good Workshop app's splash
              page. <br />
              Responsive and includes on-scroll animations.
            </p>
          </>
        }
        skills={
          <>
            Responsive Design<em>, </em>HTML<em>, </em>CSS<em>, </em>
            Javascript<em>, </em>Figma
          </>
        }
        involvement={
          <>
            <p>Design</p>
            <p>Development</p>

            <h4>Visit</h4>
            <p>
              <a
                href="https://www.thegoodworkshop.io"
                target="_blank"
                rel="noreferrer"
              >
                thegoodworkshop.io
              </a>
            </p>
          </>
        }
      />
    </Project>
  );
};
