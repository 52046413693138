import FeatImg from './FeatImg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import FooterNav from './UI/FooterNav';
import { useGlobalContext } from './hooks/GlobalContext';

const Project = ({ projectID, prllxSpeed, children }) => {
  const { setProjectID } = useGlobalContext();

  console.log('projectID', projectID);

  useEffect(() => {
    setProjectID(projectID);
    AOS.init({
      duration: 1000,
      offset: 75,
      easing: 'ease-in',
      distance: '30px',
    });

    return () => {
      AOS.refresh();
    };
  }, [setProjectID, projectID]);

  return (
    <>
      <div>
        <FeatImg prllxSpeed={prllxSpeed} />
        <div className={`project`}>{children}</div>
        <FooterNav />
      </div>
    </>
  );
};

export default Project;
