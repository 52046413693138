import { Link } from 'react-router-dom/dist';
import PAGES from './Pages';
import Parallax from 'react-rellax';
import UseIsMobile from './hooks/useIsMobile';

const Thumbs = () => {
  const isMobile = UseIsMobile();
  return (
    <div className="row works-thumbs">
      {PAGES.map((page, index) => {
        const linkBlock = (
          <Link to={`/${page.url}`}>
            <span>
              <img src={`/images/${page.url}/thumb.webp`} alt={page.name} />
            </span>
            <small>0{index + 1}.</small> {page.name}
          </Link>
        );
        return (
          <div className="col-lg-6" key={page.id}>
            {index % 2 === 0 ? (
              !isMobile ? (
                <Parallax speed={2}>{linkBlock}</Parallax>
              ) : (
                <div>{linkBlock}</div>
              )
            ) : (
              linkBlock
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Thumbs;
